<template>
    <div>
        <CCard>
          <CCardHeader style="position:relative;">
            <strong>Expenses</strong>
            <div class="card-header-actions">
                <CButton @click="addModalData = new Date()" style="position:absolute; right:10px; top:8px;" size="sm" class="branding-btn"><CIcon name="cil-plus"/></CButton>
            </div>
          </CCardHeader>
          <CCardBody>
              <ExpensesTable
              :items="expenses"
              striped
              v-on:event_child="eventChild"
              />
          </CCardBody>
        </CCard>
        <add-modal 
        :addModalData="addModalData"
        />
        <edit-modal 
        :editModalData="editModalData"
        />
        <delete-modal 
        :deleteModalData="deleteModalData"
        />
    </div>
</template>
<script>
import ExpensesTable from './ExpensesTable';
import addModal from './addModal';
import editModal from './editModal';
import deleteModal from './deleteModal';
import { mapGetters } from 'vuex';

export default {
    data(){
        return {
            items: [],
            addModalData: '',
            editModalData: '',
            deleteModalData: ''
        }
    },
    components: {
        ExpensesTable,
        addModal,
        editModal,
        deleteModal
    },
    computed: {
        ...mapGetters({ 
            expenses: "expenses/expenses"
        }),
    },
    methods: {
        eventChild(data, action){
            if(action == 'edit'){
                this.editModalData = {
                    data: data,
                    trigger: new Date()
                }
            }else{
                this.deleteModalData = {
                    data: data,
                    trigger: new Date()
                }
            }
        },
    },
    beforeCreate(){
        this.$store.dispatch('expenses/fetchExpenses');
    }
}
</script>