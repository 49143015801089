<template>
    <CModal
      title="Add Work Order"
      :show.sync="myModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">ADD EXPENSES</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput
                label="ITEM"
                v-model="form.item"
            />
            <CInput
                label="QUANTITY"
                v-model="form.qty"
                type="number"
            />
            <div class="form-group">
                <label>Unit</label>
                <CSelect
                    placeholder="Select Unit"
                    :options="units | selectData"
                    :value.sync='form.unit'
                />
            </div>
            <CInput
                label="COST"
                v-model="form.cost"
                type="number"
            />
            <CInputFile
                horizontal
                custom
                class="mb-3 px-1 custom-input-file"
                placeholder="Upload Receipt"
              />
            <v-date-picker v-model="form.date" class="custom-date-container" mode="date" color="orange">
                <template v-slot="{ inputValue, inputEvents }">
                    <label class="v-date-picker-label"> TRANSACTION DATE </label>
                    <input
                    class="px-2 py-1 custom-date-picker border mb-3 rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    style="width: 100%"
                    />
                </template>
            </v-date-picker>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="submit" color="success" class="branding-btn">ADD</CButton>
        <CButton @click="myModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data(){
        return {
            myModal: false,
            form: {
              item: '',
              qty: '',
              cost: '',
              unit: '',
              date: new Date()
            },
        }
    },
    props: ['addModalData'],
    computed: {
        ...mapGetters({ 
            units: "unit/unit"
        }),
    },
    filters: {
        selectData: function (value) {
                if(value){
                    var i;
                    var personel = [];
                    for (i = 0; i < value.length; i++) {
                        if(value[i].name){
                            personel.push({ 
                            value: value[i].name, 
                            label: value[i].name
                            }); 
                        }
                    }
                    return personel;
                }
            },
    },
    watch: {
        addModalData(){
            this.myModal = true;
        }
    },
    methods: {
        submit(){
            this.$store.dispatch('expenses/addExpenses', this.form).then(() => {
                this.myModal = false;
                this.form = {
                    item: '',
                    qty: '',
                    cost: '',
                    unit: '',
                    date: new Date()
                };
            });
        }
    },
    beforeCreate(){
        this.$store.dispatch('unit/fetchUnit');
    }
}
</script>
<style>
.custom-input-file .col-sm-9{
    max-width:100% !important;
    border: #636F83 solid 1px;
    border-radius:5px;
    flex: none !important;
}
</style>